.product-listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filter-container {
  flex-basis: 21%;
}

.productcard-container {
  flex-basis: 78%;
  padding: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  justify-content: center;
}

.product-card {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 0 15px;
  background-color: #000;
  border-radius: 12px 12px 0 0;
  gap: 10px;
  position: relative;
  cursor: pointer;
  color: #fff;
}

.product-card > img {
  width: 270px;
  height: 310px;
  border-radius: 12px;
  display: block;
  object-fit: contain;
  object-position: 50% 50%;
  transition: transform 0.3s ease;
}

.product-card > img:hover {
  transform: scale(1.06);
}

.product-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: center;
  width: 100%;
}

.product-info > h3 {
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  color: #fff;
  text-align: center;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-price {
  font-size: 20px;
  font-weight: 650;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 5px;
}

.add-to-cart-button {
  /* border: none; */
  border-top: 1px solid #525252;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  background-color: #000;
  /* width: 100%; */
  padding: 10px 0;
  color: #eee;
  width: 300px;
  border-radius: 0 0 12px 12px;
  cursor: pointer;

}

.product-wishlist {
  /* transform: translate(245px, 55px); */
  position: relative;
  z-index: 1000;
  width: 44px;
  height: 44px;
}

.wishlist-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: #000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  z-index: 1000;

  border: 1px solid #9a9a9a;
}

.product-description {
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  line-height: 22px;
  color: #888888;
  text-align: center;
  width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ratings {
  padding: 5px;
  position: absolute;
  left: 19px;
  z-index: 10000;
  border-radius: 8px;
}
