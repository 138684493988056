.single-product{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px;
}

.single-product-container{
    display: contents;
    width: 1200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #000;
    border-radius: 12px;
    padding: 20px;
    height: 720px;
    color: #eee;
}

.single-product>div>img{
    width: 500px;
    max-height: 600px;
    display: block;
    object-fit: contain;
    object-position: 50% 50%;  
}

.single-product-info{
    width: 375px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.single-product-info h3{
    font-size: 2rem;
    line-height: 2.3rem;
    font-weight: 700;
}

.single-product-description{
    color: #8d8d8d;
}

.single-product-description::first-letter{
text-transform: capitalize;
}

.single-product-price{
    font-size: 1.5rem;
    line-height: 1.2rem;
    font-weight: 700;
}

.single-wishlistButtom{
    position: relative;
    top: 0;
    left: 0;
}

.add-to-cart{
    background-color: #171717;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
}

.add-to-cart:hover{

}

.single-product-wishlist{
    width: 45px;
    height: 45px;
    transform: translate(330px, -60px) !important; 
}