.signup-page {
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 15px;
}

.signup-page h3 {
  text-align: center;
  font-size: 24px;
}

.input-container > p {
  font-size: 16px;
}

.input-container > input {
  font-size: 16px;
  width: 100%;
  padding: 10px 15px;
}

.input-container > input:focus {
  outline: none;
}

.remember-forgot-container {
  display: flex;
  justify-content: space-between;
}

.signup-button {
  padding: 8px;
  background-color: #1846ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #1131b0;
}
