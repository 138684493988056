.categories {
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
}

.category {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.category > h3 {
  position: absolute;
  background-color: #fff;
  width: 286px;
  top: 18.5%;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0px;
  color: #9a9a9a;
}
