.cart-component {
  width: 600px;
  display: flex;
  gap: 40px;
  background-color: #000;
  padding: 10px;
  border-radius: 12px;
}

.cart-img {
  width: 220px;
  height: auto;
  border-radius: 12px;
}

.cart-card-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 250px;
  color: #eee;
}

.cart-title {
  font-size: 20px;
  font-weight: 600;
}

.cart-price {
  display: flex;
  align-items: center;
  gap: 15px;
}

.cart-price > p:first-child {
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

/* .cart-price > p:last-child {
  font-size: 20px;
  font-weight: 650;
  line-height: 28px;
  color: #ababab;
  text-decoration-line: line-through;
} */

.cart-discount {
  font-size: 20px;
  font-weight: 700;
  color: #ababab;
}

.cart-quantity {
  display: flex;
  gap: 15px;
}

.cart-quantity > p {
  font-size: 16px;
  font-weight: 550;
}

.cart-quantity > button {
  border: 1px solid #fff;
  background-color: #000;
  padding: 6px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.cart-button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-button-container>button {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }

  .cart-button-container>button:first-of-type {
    background-color: transparent;
    border: 1px solid #525252;
    color: #eee;
  }

  .cart-button-container>button:first-of-type:hover {
    font-size: 1.02rem;
  }

  .cart-button-container>button:last-of-type {
    background-color: transparent;
    border: 1px solid #525252;
    color: #eee;
  }

  .cart-button-container>button:last-of-type:hover {
    font-size: 1.02rem;
  }