.cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 60px;
  margin-top: 20px;
  color: #eee;
}

.cart>h3{
  font-size: 20px;
  font-weight: 700;
}

.cart-conatiner{
  display: flex;
  gap: 120px;
}

.cart-product-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
