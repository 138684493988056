.navbar {
  display: flex;
  background-color: #171717;
  padding: 1%;
  height: 60px;
  justify-content: space-around;
  align-items: center;
}

.logo > a > h3{
  color: #eee;
}

.searchbar {
  display: flex;
  align-items: center;
  border: 1px solid #9a9a9a;
  padding: 8px;
  width: 421px;
  height: 40px;
  gap: 5px;
  border-radius: 5px;
}

.searchbar-input {
  width: 100%;
  height: 100%;
  border: 0;
  font-size: 16px;
  background-color: #171717;
  color: #eee;
}

.searchbar-input:focus {
  outline: none;
}

.right-container {
  display: flex;
  width: 500px;
  justify-content: space-evenly;
  align-items: center;
}

.navbar-home{
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #eeeeee;
}

.navbar-product{
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #eeeeee;
}

.navbar-cart {
  display: flex;
}

.navbar-wishlist {
  display: flex;
}

.navbar-counter {
  display: flex;
  width: 20px;
  height: 20px;
  background-color: #ef4444;
  border-radius: 50%;
  justify-content: center;
  /* align-items: center; */
  color: #eeeeee;
  transform: translate(-10px, -10px);
}

.navbar-counter > p {
  transform: translateY(-1px);
}

.navbar-cart > a > p {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 24px; */
  text-align: center;
  color: #eee;
}

.login-button-navbar {
  height: 40px;
  width: 100px;
  border-radius: 0px;
  border: none;
  cursor: pointer;
  background-color: #212121;
  display: flex;
  justify-content: center;
  transition: all 0.8s ease-in-out;
}

.login-button-navbar:hover {
  border: 1px solid #171717;
}

.login-button-navbar > p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchbar-result{
  flex-direction: column;
}