.filter-container {
  width: 325px;
  position: sticky;
  background-color: #000;
  height: 100vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  top: 0;
  color: #eee;
}

.filter-heading-clear {
  display: flex;
  justify-content: space-between;
}

.filter-heading-clear > h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.filter-heading-clear > button {
  background-color: #000;
  border: none;
  text-decoration-line: underline;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #eee;
}

.filter-price > h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.filter-price > div > div {
  display: flex;
  justify-content: space-between;
  width: 275px;
  margin-bottom: 3px;
}

.filter-price > div > div > label {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #b2b2b2;
}

.price-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background-color: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: #24ff00; */
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* background-color: #24ff00; */
  cursor: pointer;
}

.filter-by-category > h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.checkbox-input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-input > label {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.radio-input-container {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.filter-by-rating>h3{
  margin-bottom: 10px;
}

input[type="radio"] {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  cursor: pointer;
}

.radio-input-container>label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
