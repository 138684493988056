.cart-details {
  width: 400px;
  background-color: #000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 12px;
  position: sticky;
  top: 50px;
  color: #eee;
}

.cart-details > h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
}

.divider {
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background-color: #eee;
}

.cart-details-price > span {
  display: flex;
  justify-content: space-between;
  padding: 8px;
}

.cart-details-price > span > label {
  font-size: 18px;
  /* line-height: 28px; */
  font-weight: 500;
}

.cart-details-total {
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
}

.cart-details-total > label {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.discount-info {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.place-order {
  padding: 8px;
  background-color: #2563EB;
  color: #eee;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.place-order:hover {
  background-color: #2563EB;
}
