.home{
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
}

.homepagePoster>img{
    width: 1479.2px;
    height: 400px;
}